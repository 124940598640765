function OwinPass() {
  return (
    <div className='owin-pass-wrapper'>
      <a href={'https://m.hanacard.co.kr/MKCDCM1000M.web?CD_PD_SEQ=15617'}>오윈 하나카드 신청하기</a>
      <img
        className={'owin-pass-background-image-pc'}
        src={
          require("../assets/event/230508-pc-main.webp")
        }
        alt={'오윈 제휴카드 구독 서비스 무료!'}/>
      <img
        className={'owin-pass-background-image-mobile'}
        src={require("../assets/event/230508-mobile-main.webp")}
        alt={'오윈 제휴카드 구독 서비스 무료!'}/>
    </div>
  );
}

export default OwinPass;
