import { Outlet } from "react-router-dom";
import Header from "./Header";
import "../App.scss";

export default function Layout() {
  return (
    <div id="ko">
      <Header/>
      <Outlet/>
    </div>
  )
}
