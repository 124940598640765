export default function EnSectionContact() {
  return (
    <section
      id="contact"
      className={`position-relative service section section-contact`}
    >
      <div
        className="container-fluid section-inner"
      >
        <div className="section-contact-content en-content">
          <h3 className="section-contact-title">Do you have <br/>any questions?</h3>
          <p className="section-contact-description">
            Please contact us for more information about
            in-car-payment. <br/>We will be happy to help.
          </p>
          <a className="section-contact-mailto" href="mailto:partners@owin.kr">Contact Us</a>
        </div>
      </div>
    </section>
  );
}
