import { Fragment } from "react";
import { section4 } from "../assets/dummy/dummy";

export default function Section4({ id }: any) {
  return (
    <div>
      {section4.map((ite, idx) => {
        var innerId = idx === 0 ? "SERVICE" : "PRODUCT";
        return (
          <div
            key={idx}
            id={innerId}
            className="carousel slide position-relative service"
            data-bs-ride="false"
          >
            <div
              className="carousel-inner"
              style={{
                position: "relative",
              }}
            >
              {ite.map((item, index) => (
                <div
                  key={index}
                  className={`carousel-item main-section ${
                    index === 0 && "active"
                  }`}
                >
                  <div
                    className="container-fluid carousel-item-inner"
                    style={{
                      backgroundImage: item.backgroundImage,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ zIndex: 99 }}>
                      <p
                        className="section1-title section1-title2"
                        style={{ fontWeight: "bold" }}
                      >
                        {item.text1}
                      </p>
                      <p className="section1-title">{item.text2}</p>
                      <div className="section1-text1-container row g-0">
                        {item.text3.split("@").map((ti, index) => (
                          <div className="section1-text1" key={index}>
                            {ti}
                          </div>
                        ))}
                      </div>
                      <div className="carousel-inner-buttons ">
                        {item.brands?.map((item, idx) => (
                          <img
                            key={idx}
                            className="brands"
                            src={item}
                            style={{ height: "6vh" }}
                          />
                        ))}
                      </div>
                    </div>
                    {idx === 1 && index === 0 && (
                      <img
                        className="sm-none"
                        style={{
                          position: "absolute",
                          right: 40,
                          width: "40vmax",
                        }}
                        src={require("../assets/png/PD_type0_sub.png")}
                      />
                    )}
                    <div
                      className="page"
                      style={{
                        position: "absolute",
                        bottom: "13vh",
                        right: "10vw",
                      }}
                    >
                      <span className="section1-title">
                        <strong>0{index + 1}</strong>
                      </span>
                      <span className="section1-text1">
                        <strong>/0{ite.length}</strong>
                      </span>
                    </div>
                  </div>
                </div>
              ))}

              <div
                className="carousel-indicators"
                style={{
                  bottom: "20%",
                  margin: "0 10vw",
                  height: 20,
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 600,
                }}
              >
                {ite.map((it, idx) => (
                  <Fragment key={idx}>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <li
                        data-bs-target={`#${innerId}`}
                        data-bs-slide-to={idx}
                        className={idx === 0 ? "active" : ""}
                        aria-current={idx === 0 ? "true" : "false"}
                        aria-label={`${idx}th slide`}
                        style={{
                          margin: 0,
                          border: "2px solid white",
                          borderRadius: 100,
                          width: 12,
                          height: 12,
                        }}
                      ></li>
                      <strong
                        style={{
                          marginTop: 24,
                          width: "18vw",
                          position: "absolute",
                          color: "white",
                          fontSize: 16,
                          textAlign: "center",
                        }}
                      >
                        {it.name}
                      </strong>
                    </div>
                    {idx === ite.length - 1 || (
                      <div
                        className="carousel-bar"
                        style={{
                          backgroundColor: "white",
                          height: 2,
                          width: "30%",
                        }}
                      />
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
