export default function Section1({ id, data, type, children }: any) {
  return (
    <div
      id={id}
      className={`carousel slide position-relative service ${type} section1`}
      data-bs-ride={id === "SERVICE" ? "carousel" : "false"}
    >
      <div
        className="carousel-inner"
        style={{
          position: "relative",
        }}
      >
        <div
          className="carousel-indicators"
          style={{
            margin: "0 10vw",
            height: 20,
          }}
        >
          {data.map((item: any, index: any) => (
            <li
              key={index}
              data-bs-target={`#${id}`}
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0 ? "true" : "false"}
              aria-label={`${index}th slide`}
            ></li>
          ))}
        </div>

        {children}

        {data.map((item: any, index: any) => (
          <div
            key={index}
            className={`carousel-item main-section ${index === 0 && "active"}`}
          >
            <div
              className="container-fluid carousel-item-inner"
              style={{
                backgroundImage: item.backgroundImage,
                backgroundSize: "cover",
                backgroundPosition: item.backgroundPosition ?? "center center",
                width: "100%",
                height: "100%",
                display: "flex",
                // alignItems: "center",
              }}
            >
              <div style={{ width: "100%" }}>
                <p className="section1-title">{item.title}</p>
                <div className="section1-text1-container row g-0">
                  {item.text1.split("@").map((ti: any, idx: any) => (
                    <div className="section1-text1" key={idx}>
                      {ti}&nbsp;
                    </div>
                  ))}
                </div>
                <div className="carousel-inner-buttons">
                  {item.buttons?.map((item: any, idx: any) => (
                    <img
                      key={idx}
                      src={item.img}
                      style={{ height: "5vh", cursor: "pointer" }}
                      onClick={() =>
                        item.url.startsWith("/")
                          ? (window.location.href = item.url)
                          : window.open(item.url, "_blank")
                      }
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}

        <div
          className="carousel-control"
          style={{
            position: "absolute",
            display: "flex",
            height: 50,
            bottom: "12vmax",
            width: "100%",
          }}
        >
          <div
            style={{
              width: 100,
              height: 50,
              margin: "0 10vw",
              position: "absolute",
            }}
          >
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target={`#${id}`}
              data-bs-slide="prev"
              style={{ width: 48 }}
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target={`#${id}`}
              data-bs-slide="next"
              style={{ width: 48 }}
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
