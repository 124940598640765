import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import OwinPass from "./pages/OwinPass";
import OwinXHana from "./pages/OwinXHana";
import EnMain from "./pages/en/EnMain";
import Layout from "./component/Layout";
import EnLayout from "./component/en/EnLayout";

export default function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route index element={<Main/>}></Route>
            <Route path="owin_pass" element={<OwinPass/>}></Route>
            <Route path="owinXhana" element={<OwinXHana/>}></Route>
          </Route>
          <Route path="/en" element={<EnLayout/>}>
            <Route index element={<EnMain/>}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
