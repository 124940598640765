import NavLanguage from "../NavLanguage";

export default function EnFooter() {
  return (
    <footer className="footer">
      <div className="en-content">
        <ul className="footer-items">
          <li className="footer-item footer-item-mail">
            <span>MAIL</span>
            <a href="mailto:partners@owin.kr">partners@owin.kr</a>
          </li>
          <li className="footer-item">
            Copyright © OWiN Corp. All Rights Reserved.
          </li>
        </ul>
      </div>
    </footer>
  );
}
