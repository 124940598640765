import EnFooter from "../../component/en/EnFooter";
import EnSectionHome from "../../component/en/EnSectionHome";
import EnSectionProvide from "../../component/en/EnSectionProvide";
import EnSectionVideo from "../../component/en/EnSectionVideo";
import EnSectionPartnership from "../../component/en/EnSectionPartnership";
import EnSectionContact from "../../component/en/EnSectionContact";

export default function EnMain(props: any) {
  return (
    <div>
      <EnSectionHome/>
      <EnSectionProvide/>
      <EnSectionVideo/>
      <EnSectionPartnership/>
      <EnSectionContact/>
      <EnFooter/>
    </div>
  );
}
