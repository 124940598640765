import { pass } from "../assets/dummy/dummy";
export default function Section3() {
  return (
    <div id="PASS">
      {pass.map((item, index) => {
        var id = item.title.split(".")[0];
        return (
          <div
            key={index}
            className="main-section row row-cols-1 row-cols-sm-1 row-cols-md-2 g-0 pass"
          >
            <div
              className={`col order-2 ${
                index === 1 ? "order-md-2" : "order-md-1"
              }`}
              style={{ backgroundColor: "white" }}
            >
              <div className="pass-title col-12 col-md-6">{item.title}</div>
              <div
                id={id}
                className="carousel carousel-dark slide"
                data-bs-ride="false"
              >
                <div
                  className="carousel-inner"
                  style={{
                    position: "relative",
                  }}
                  role="listbox"
                >
                  <div
                    className="carousel-indicators"
                    style={{
                      bottom: "10vw",
                      margin: "0 10vw",
                      height: 20,
                    }}
                  >
                    {item.list.map((product, index) => (
                      <li
                        key={index}
                        data-bs-target={`#${id}`}
                        data-bs-slide-to={index}
                        className={index === 0 ? "active" : ""}
                        aria-current={index === 0 ? "true" : "false"}
                        aria-label={`${index}th slide`}
                      ></li>
                    ))}
                  </div>
                  {item.list.map((product, index) => (
                    <div
                      key={index}
                      className={`carousel-item main-section ${
                        index === 0 && "active"
                      }`}
                    >
                      <div
                        className="container-fluid carousel-item-inner"
                        style={{
                          width: "100%",
                          height: "100%",
                          background:
                            "linear-gradient(to bottom, rgba(0,0,0,0) 30vh, white 50%)",
                        }}
                      >
                        <div className="col-12 desc" color="#6E6E6E">
                          {product.desc}
                        </div>
                        <div
                          style={{
                            backgroundColor: "black",
                            padding: "7.5px 16px",
                            color: "white",
                            borderRadius: 100,
                            fontSize: "2vh",
                            width: "fit-content",
                            marginTop: "5vmax",
                            fontFamily: "NanumGothicBold",
                          }}
                        >
                          {product.text1}
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #2e2e2e",
                            paddingBottom: 10,
                            marginTop: 20,
                          }}
                        >
                          <span className="disct">{product.text2}</span>{" "}
                          <span className="disct-desc"> {product.text3}</span>
                        </div>
                        <div
                          className="disct-desc"
                          style={{ marginTop: 20, color: "#6e6e6e" }}
                        >
                          {product.text4}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target={`#${id}`}
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target={`#${id}`}
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>

            <div
              className={`col order-1 ${
                index === 1 ? "order-md-1" : "order-md-2"
              }`}
              style={{
                backgroundImage: `url(${item.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            ></div>
          </div>
        );
      })}
    </div>
  );
}
