export default function EnSectionPartnership() {
  return (
    <section
      className={`position-relative service section section-partnership`}
    >
      <div
        className="container-fluid section-inner"
      >
        <div className="section-partnership-content en-content">
          <h3 className="section-partnership-title">Strategic<br/>
            Partnership</h3>
          <div className="section-partnership-split"></div>
          <ul className="section-partnership-brands">
            <li className="section-partnership-brand">
              <img src={require("../../assets/png/en/bi_benz.png")} alt="Mercedes Benz" width="174"/>
            </li>
            <li className="section-partnership-brand">
              <img src={require("../../assets/png/en/bi_hyundai.png")} alt="Hyundai" width="79"/>
            </li>
            <li className="section-partnership-brand">
              <img src={require("../../assets/png/en/bi_renault.png")} alt="Renault Samsung" width="82"/>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
