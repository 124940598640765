export default function EnSectionHome() {
  return (
    <section
      id="home"
      className={`position-relative service section section-home`}
      style={{ backgroundImage: `linear-gradient( rgba(34, 34, 34, 0.4), rgba(34, 34, 34, 0.4) ), url(${require("../../assets/png/en/en_section_home_bg.webp")})` }}
    >
      <div
        className="container-fluid section-inner"
      >
        <div className="section-home-content en-content">
            <p className="section-home-sub-title">OWiN IN-CAR-PAYMENT</p>
            <h3 className="section-home-title">Connected Car<br/>
              Commerce Service Aggregator</h3>
            <p className="section-home-description">
              We offer a cutting-edge in-car-payment system that enables customers to easily pay <br/>
              for fuel, parking, and food & beverage curbside pickup while on the go.
            </p>
        </div>
      </div>
    </section>
  );
}
