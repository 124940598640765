import dayjs from "dayjs";
import { ReactComponent as Tab } from "../assets/svg/Tab.svg";
import NavLanguage from "./NavLanguage";

export default function Header() {
  const test = {
    "data-bs-toggle": "collapse",
    "data-bs-target": "#navbarCollapse",
  };

  return (
    <header>
      <nav className={`navbar navbar-expand-md navbar-dark fixed-top`} id="navbar">
        <div className="container-fluid" style={{ padding: 0 }}>
          <a className="navbar-brand" href="/">
            <img id="owin_logo" src={require("../assets/png/owin_logo.png")} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{
              borderWidth: 0,
              paddingRight: 0,
              marginRight: "-10px",
            }}
          >
            <Tab />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
              <li className="nav-item">
                <a className="nav-link" href="/owin_pass">
                  OWIN PASS
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  {...test}
                  onClick={() => (window.location.href = "/#SERVICE")}
                >
                  SERVICE
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarCollapse"
                  onClick={() => (window.location.href = "/#PRODUCT")}
                >
                  PRODUCT
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarCollapse"
                  onClick={() => (window.location.href = "/#NEWS")}
                >
                  NEWS
                </a>
              </li>
              {dayjs() < dayjs("20230531235959", "YYYYMMDDHHmmss") && (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="/owinXhana"
                    style={{ position: "relative" }}
                  >
                    EVENT
                    <span
                      className="newBage"
                      style={{
                        color: "black",
                        fontFamily: "NanumGothicBold",
                        fontSize: 10,
                        backgroundColor: "#00fa5c",
                        borderRadius: 2,
                        width: 14,
                        height: 14,
                        textAlign: "center",
                        position: "absolute",
                        right: -20,
                      }}
                    >
                      <strong>N</strong>
                    </span>
                  </a>
                </li>
              )}
              <NavLanguage language={"ko"} />
            </ul>
          </div>
          <div
            id="nav_sns"
            style={{
              position: "absolute",
              bottom: "40vh",
              zIndex: 888,
              width: "87vw",
              textAlign: "center",
            }}
          >
            <img
              src={require("../assets/png/sns_youtube.png")}
              onClick={() => {
                window.location.href = "https://www.youtube.com/@owin_official";
              }}
            />
            <img
              src={require("../assets/png/sns_insta.png")}
              onClick={() => {
                window.location.href = "https://www.instagram.com/owin__official/";
              }}
            />
            <img
              src={require("../assets/png/sns_facebook.png")}
              onClick={() => {
                window.location.href = "https://www.facebook.com/owinauto";
              }}
            />
            <img
              src={require("../assets/png/sns_blog.png")}
              onClick={() => {
                window.location.href = "https://blog.naver.com/owin_carpay";
              }}
            />
          </div>
        </div>
      </nav>
    </header>
  );
}
