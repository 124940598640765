export default function EnSectionPartnership() {
  return (
    <section
      id="pay"
      className={`position-relative service section section-video`}
      style={{ backgroundImage: `linear-gradient( rgba(34, 34, 34, 0.4), rgba(34, 34, 34, 0.4) ), url(${require("../../assets/png/en/en_section_pay_bg.webp")})` }}
    >
      <div
        className="container-fluid section-inner"
      >
        <div className="section-video-content en-content">
          <h3 className="section-video-title"><span>Pay with OWiN</span><br/>
            <span className="section-video-title--mobile-right">from within </span><span>the Vehicle</span></h3>
          <div className="section-video-video">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/zEfYqbSEkFw"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}
