import { ReactComponent as EarthIcon } from "../assets/svg/en/earth_icon.svg";

export default function NavLanguage({ language }: { language: string }) {
  const handleClick = (language: string) => {
    localStorage.setItem('locale', language);
  }
  return (
    <div className="nav-language">
      <EarthIcon className='nav-language-earth-icon'/>
      <a href="/" title="KOR" className={`nav-language-item ${language === 'ko' ? 'nav-language-item--active' : ''} `}
         onClick={() => handleClick('ko')}><span>KOR</span></a>
      <div className="nav-language-split"></div>
      <a href="/en" title="ENG" className={`nav-language-item ${language === 'en' ? 'nav-language-item--active' : ''} `}
         onClick={() => handleClick('en')}><span>ENG</span></a>
    </div>
  )
}