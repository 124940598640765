import NavLanguage from "./NavLanguage";

export default function Footer() {
  return (
    <div
      className="w-100 h-25 bg-dark footer"
      style={{ padding: "100px 10vw" }}
    >
      <div style={{ marginBottom: 70 }}>
        <img id="owin_logo" src={require("../assets/png/owin_logo.png")}/>
      </div>
      <div
        style={{
          borderBottom: "1px solid #707070",
          paddingBottom: 30,
          marginBottom: 30,
        }}
      >
        <div>
          <span>
            <strong>주소</strong> 서울시 서초구 반포대로27길 16 파크빌딩 5,6층
            오윈
          </span>
          <span>
            <strong>TEL</strong> 1588-8701(근무시간 09:00~18:00, 토/공휴일 휴무)
          </span>
          <span>
            <strong>FAX</strong> 02-6305-4155
          </span>
        </div>

        <span>
          <strong>MAIL</strong> cs@owin.kr
        </span>
        <span>
          <strong>대표이사</strong> 정도균
        </span>
        <span>
          <strong>사업자등록번호</strong> 220-88-96700
        </span>
        <span>
          <strong>통신판매신고</strong> 제 2015-서울강남-03069
        </span>
      </div>
      <div
        className="footer-copyright-wrapper"
      >
        {/* <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <a>서비스이용약관</a>
          <a>개인정보처리방침</a>
          <a>이용약관</a>
        </div> */}
        <span className="footer-copyright">Copyright ⓒ OWiN Corp. All Rights Reserved</span>
      </div>
    </div>
  );
}
