export const service1 = [
  {
    backgroundImage: `url(${require("../png/main_1.webp")})`,
    title: (
      <>
        <strong>스마트폰 앱</strong>에서도
        <br />
        <strong>차량 인포테인먼트 앱</strong>에서도
      </>
    ),
    text1:
      "하나의 앱으로 주유, 주차, 세차, 대리, 결제는 물론  @ 주차걱정 없는 픽업 서비스까지 @편리한 운전자 감동 서비스를 최대 할인과 함께",
    buttons: [
      {
        img: require("../png/GooglePlay.png"),
        url: "https://play.google.com/store/apps/details?id=kr.owin.pick",
      },
      {
        img: require("../png/AppStore.png"),
        url: "https://apps.apple.com/kr/app/pick-by-owin/id1110255433",
      },
    ],
  },
  {
    backgroundImage: `url(${require("../png/main_2.webp")})`,
    title: (
      <>
        가시는 <strong>경로상</strong>에 있는
        <br />
        <strong>최고의 매장</strong>을 추천드려요
      </>
    ),
    text1:
      "여행갈때 돌아가긴 싫고, @커피, 김밥, 편의점이 필요하다면 @경로상에 있는 최적의 상품을 추천드립니다.",
    buttons: [
      {
        img: require("../png/GooglePlay.png"),
        url: "https://play.google.com/store/apps/details?id=kr.owin.pick",
      },
      {
        img: require("../png/AppStore.png"),
        url: "https://apps.apple.com/kr/app/pick-by-owin/id1110255433",
      },
    ],
  },
];

export const service2 = [
  // {
  //   backgroundImage: `url(${require("../png/main_3.webp")})`,
  //   title: (
  //     <>
  //       <strong>오윈하나카드</strong>출시기념
  //     </>
  //   ),
  //   text1: "모빌리티구독서비스 100%청구할인!",
  //   buttons: [
  //     {
  //       img: require("../png/section2_1.png"),
  //       url: "https://play.google.com/store/apps/details?id=kr.owin.pick",
  //     },
  //   ],
  // },
  // {
  //   backgroundImage: `url(${require("../png/main_3.webp")})`,
  //   title: (
  //     <>
  //       <strong>오윈하나카드</strong> 출시
  //     </>
  //   ),
  //   text1: "", //"모빌리티구독서비스 100%청구할인!",
  //   buttons: [
  //     {
  //       img: require("../png/section2_1.png"),
  //       url: "https://m.hanacard.co.kr/MKCDCM1010M.web?CD_PD_SEQ=15618&utm_source=owin&utm_medium=affiliates&utm_campaign=202212_owin",
  //     },
  //   ],
  // },
  {
    backgroundImage: `url(${require("../png/main_4.webp")})`,
    title: (
      <>
        오윈 구독, <strong>오윈 패스</strong>
      </>
    ),
    text1:
      "차 안에서 어떤 서비스를 자주 이용하시나요?@월 9,900원으로 운전자를 위한 다양한 혜택을 @쉽고 간편하게 누려보세요.",
    buttons: [
      {
        img: require("../png/section2_2.png"),
        url: "/owin_pass",
      },
    ],
  },
  {
    backgroundImage: `url(${require("../png/main_5.webp")})`,
    title: (
      <>
        한눈에 보는 <strong>오윈 사용법</strong>
      </>
    ),
    text1:
      "운전자라면 한 번쯤 눈여겨봐야 할 서비스 오윈.@오윈의 주유, 드라이브 스루, 주차, 발렛 서비스 사용법을 @쉽게 알려드릴게요.",
    buttons: [
      {
        img: require("../png/section3_1.png"),
        url: "https://www.youtube.com/watch?v=C_2MHI7xdV4",
      },
      {
        img: require("../png/section3_2.png"),
        url: "https://www.youtube.com/watch?v=C_2MHI7xdV4",
      },
      {
        img: require("../png/section3_3.png"),
        url: "https://blog.naver.com/owin_carpay/222946821922",
      },
      {
        img: require("../png/section3_4.png"),
        url: "https://blog.naver.com/owin_carpay/222900165605",
      },
    ],
  },
];

export const pass = [
  {
    title: "A.실속형",
    image: require("../png/1.webp"),
    list: [
      {
        desc: "주유 최대 할인 도전!",
        text1: "실속 큰 혜택",
        text2: (
          <>
            주유 <strong>60원/L</strong>
          </>
        ),
        text3: "현장 할인 (150L/월 한도)",
        text4: (
          <>
            *주유기 앞에서 앱화면에 주유기 번호만 선택하시면
            <br />
            주유부터 결제까지 진행됩니다.
          </>
        ),
      },
      {
        desc: "바쁜 일상, 픽업은 차 안에서!",
        text1: "실속 추가2",
        text2: (
          <>
            식사/음료 <strong>3,000원 쿠폰2매</strong>
          </>
        ),
        text3: "",
        text4: (
          <>
            *픽업존에 계시면 매장에서
            <br />차 안으로 상품을 전달해 드립니다.
          </>
        ),
      },
      {
        desc: "주차, 세차 할인 도전!",
        text1: "실속 추가3",
        text2: (
          <>
            + 세차 <strong>3% 할인</strong>
            <br />
            주차 <strong>7% 할인 쿠폰1매</strong>
          </>
        ),
        text3: "(당일권 한정)",
        text4: <>*오윈 앱에서 예약부터 결제까지 한번에 진행됩니다.</>,
      },
    ],
  },
  {
    title: "B.얼리어답터형",

    image: require("../png/3.webp"),
    list: [
      {
        desc: "차량을 사랑한다면!",
        text1: "알뜰한 혜택1",
        text2: (
          <>
            + 세차 <strong>5% 할인</strong>
            <br />
            주차 <strong>7% 할인 쿠폰2매</strong>
          </>
        ),
        text3: "(당일권 한정)",
        text4: <>*오윈 앱에서 예약부터 결제까지 한번에 진행됩니다.</>,
      },
      {
        desc: "드라이브스루처럼 픽업!",
        text1: "알뜰한 혜택2",
        text2: (
          <>
            식사/음료 <strong>3,000원 쿠폰1매</strong>
            <br />+ 식사/음료 <strong>5,000원 쿠폰1매</strong>
          </>
        ),
        text3: "",
        text4: (
          <>
            *픽업존에 계시면 매장에서
            <br />차 안으로 상품을 전달해 드립니다.
          </>
        ),
      },
      {
        desc: "알뜰하게 주유 할인과 포인트 적립!",
        text1: "알뜰한 혜택3",
        text2: (
          <>
            주유 <strong>40원/L</strong>
          </>
        ),
        text3: "현장 할인 (150L/월 한도)",
        text4: (
          <>
            *주유기 앞에서 앱화면에 주유기 번호만 선택하시면
            <br />
            주유부터 결제까지 진행됩니다.
          </>
        ),
      },
    ],
  },
  {
    title: "C.가족형",
    image: require("../png/1.webp"),
    list: [
      {
        desc: "모든 매장을 드라이브스루 처럼!",
        text1: "메인혜택",

        text2: (
          <>
            식사/음료 <strong>5% </strong>무제한 상시할인
            <br />+ 식사/음료 <strong>5,000원 쿠폰1매</strong>
          </>
        ),
        text3: "",
        text4: (
          <>
            *픽업존에 계시면 매장에서
            <br />차 안으로 상품을 전달해 드립니다.
          </>
        ),
      },
      {
        desc: "주유 할인 및 포인트 적립은 기본!",
        text1: "추가혜택1",
        text2: (
          <>
            주유 <strong>20원/L</strong>
          </>
        ),
        text3: "현장 할인 (150L/월 한도)",
        text4: (
          <>
            *주유기 앞에서 앱화면에 주유기 번호만 선택하시면
            <br />
            주유부터 결제까지 진행됩니다.
          </>
        ),
      },
      {
        desc: "복잡한 차량 관리도 한 번에!",
        text1: "추가혜택2",
        text2: (
          <>
            + 세차 <strong>5% 할인</strong>
            <br />
            주차 <strong>7% 할인 쿠폰3매</strong>
          </>
        ),
        text3: "(당일권 한정)",
        text4: <>*오윈 앱에서 예약부터 결제까지 한번에 진행됩니다.</>,
      },
    ],
  },
];

export const section4 = [
  [
    {
      name: "Pick up",
      backgroundImage: `url(${require("../png/AF_type0.webp")})`,
      text1: "모든 매장을",
      text2: <strong>드라이브 스루처럼!</strong>,
      text3:
        "드라이브 스루 걱정없이 일반매장에서도@주차 걱정없이, 차에서 내릴 필요없이,@매장 앞, 도착버튼 한번이면 차안으로 촥!",
      brands: [
        require("../png/AF_type0_icon0.png"),
        require("../png/AF_type0_icon1.png"),
        require("../png/AF_type0_icon2.png"),
        require("../png/AF_type0_icon3.png"),
        require("../png/AF_type0_icon4.png"),
      ],
    },
    {
      name: "Fuel & Charging",
      backgroundImage: `url(${require("../png/AF_type1.webp")})`,
      text1: "카드없이",
      text2: <strong>할인, 적립도 알아서!</strong>,
      text3:
        "주유&충전 세팅부터 자동결제까지 똑똑하고 편리하게,@결제카드도 멤버십 카드도, 화면 터치로!@점원과의 어색한 접촉도 없이 스마트하게 주유하세요",
      brands: [
        require("../png/AF_type1_icon0.png"),
        require("../png/AF_type1_icon1.png"),
      ],
    },
    {
      name: "Parking",
      backgroundImage: `url(${require("../png/AF_type2.webp")})`,
      text1: "저렴하고 편한",
      text2: <strong>주차권과 자동출차!</strong>,
      text3:
        "주차 할인권 구매 주차장에서@또는 사전 등록된 자동출차 주차장에서@알아서 주차 게이트가 열리고 결제도 알아서 자동으로~",
      brands: [require("../png/AF_type2_icon0.png")],
    },
    {
      name: "Others",
      backgroundImage: `url(${require("../png/AF_type3.webp")})`,
      text1: "스마트폰 앱에서도",
      text2: <strong>차량 인포테인먼트 앱에서도!</strong>,
      text3:
        "하나의 앱으로 주유,주차,세차@결제는 물론, 주차 걱정없는 픽업 서비스까지@편리한 운전자 감동 서비스를 최대 할인과 함께!",
      brands: [
        require("../png/AF_type3_icon0.png"),
        require("../png/AF_type3_icon1.png"),
        require("../png/AF_type3_icon2.png"),
      ],
    },
  ],

  [
    {
      name: "법인주유 Solution",
      backgroundImage: `url(${require("../png/PD_type0.png")})`,
      text1: "법인차량 및 물류차량 결제 관리 운영",
      text2: <strong>법인차량 주유 관리 솔루션</strong>,
      text3:
        "1. 법인차량 부정 주유 사용 방지@2. 멤버쉽 연동 및 카드사 공동 영업을 통한 주유 비용 할인@3. 주유결제 정산, 관리 비용 절감",
      brands: [require("../png/PD_type0_icon0.png")],
    },
    {
      name: "노상주차 Solution",
      backgroundImage: `url(${require("../png/PD_type1.webp")})`,
      text1: "스마트 주차 IOT 디바이스",
      text2: <strong>스마트 시티 주차 솔루션</strong>,
      text3:
        "1. 주차 상황 인식 정확도, 통신 효율을 확보한 스마트 주차 IOT 디바이스@2. RF신호를 활용한 자동결제 솔루션@3. IOT 디바이스 관제/스마트 시티 통합 주차 관리 시스템 연계 통합 서비스 솔루션",
      brands: [
        require("../png/PD_type1_icon0.png"),
        require("../png/PD_type1_icon1.png"),
      ],
    },
  ],
];
