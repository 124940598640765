function OwinXHana() {
    return (
        <div>
            <img
                onClick={() => {
                    window.location.href = "https://m.hanacard.co.kr/MKCDCM1000M.web?CD_PD_SEQ=15617";
                }}
                style={{ width: "100%", backgroundColor: "#2E2E2E" }}
                src={window.innerWidth > 1115 ? require("../assets/event/owinXhana_1.webp") : require("../assets/event/owinXhana_mobile_1.webp")}
            />
            {/* <Footer /> */}
        </div>
    );
}

export default OwinXHana;
