import React from "react";
import { ReactComponent as LeftArrow } from "../../assets/svg/BlackLeftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/svg/BlackRightArrow.svg";

export default function EnSectionProvide() {
  const navItems = ['As An In-Car-Payment Solution', 'As An In-car-Ad Solution Provider'];
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);
  const handleNavItemClick = (event: any, index: number) => {
    setCurrentSlideIndex(index);
  }
  const handleControlClick = () => {
    document.getElementById(`SectionProvide${currentSlideIndex === 0 ? 1 : 0}`)?.click();
  }

  return (
    <section
      id="provide"
      className={`position-relative section section-provide`}
    >
      <div
        className="container-fluid section-inner"
      >
        <div className="section-provide-content en-content">
          <h3 className="section-provide-title">What We Provide</h3>
          <div className="section-provide-carousel carousel slide" id="SectionProvideCarousel" data-bs-ride="false"
               data-bs-touch="false">
            {/* carousel navigation */}
            <div className="section-provide-carousel-nav">
              {/* carousel direction control */}
              <div
                className={`section-provide-carousel-nav-control section-provide-carousel-nav-control-prev ${currentSlideIndex === 1 ? 'section-provide-carousel-nav-control--inactive' : ''}`}
                onClick={handleControlClick}>
                <LeftArrow/>
              </div>
              <div
                className={`section-provide-carousel-nav-control section-provide-carousel-nav-control-next ${currentSlideIndex === 0 ? 'section-provide-carousel-nav-control--inactive' : ''}`}
                onClick={handleControlClick}>
                <RightArrow/>
              </div>
              {/* carousel navigation items */}
              {navItems.map((item, index) => (
                <div data-bs-target="#SectionProvideCarousel" data-bs-slide-to={index}
                     key={index}
                     id={`SectionProvide${index}`}
                     onClick={(e) => handleNavItemClick(e, index)}
                     className={`${currentSlideIndex === index ? 'section-provide-carousel-nav-item--active' : ''} section-provide-carousel-nav-item`}
                     style={{ marginLeft: `${index === 0 && currentSlideIndex === 1 ? '-100' : '0'}%` }}
                > {item}
                </div>
              ))}
              {/* carousel navigation indicator */}
              <div className="section-provide-carousel-nav-bottomline"></div>
              <div
                className={`${currentSlideIndex === 0 ? 'section-provide-carousel-nav-indicator--left' : 'section-provide-carousel-nav-indicator--right'} section-provide-carousel-nav-indicator`}
              ></div>
            </div>
            {/* provide items */}
            <div className="carousel-inner">
              {/* provide item - 1 */}
              <div className="carousel-item active section-provide-item" id="SectionProvide1">
                <h5>
                  Mobile App and In-Car Infotainment System
                </h5>
                <p>
                  The in-car-payment and service system should be integrated into a mobile app and in-car infotainment
                  system due to the limited functionality of infotainment (data volume, UX, etc.). Additional functions
                  such as payment card registration, payment history inquiry, coupon registration, etc. should be
                  provided through mobile app.
                </p>
                <h5>
                  POS (Point-Of-Sale) Integration Solutions for Various Type of Merchants
                </h5>
                <p>
                  When it comes to POS integration solutions for in-car-payments, there are many different requirements
                  and needs
                  for different types of merchants.
                </p>
                <h5>
                  Payment and Settlement
                  System
                </h5>
                <p>
                  Integrated with a payment gateway to accept a variety of payment methods, including credit and debit
                  cards, mobile payments
                  and other electronic payment methods, with policies and solutions that settle to actual service
                  providers, excluding platform fees.
                </p>
                <h5>
                  Customer Service and Support Solution
                </h5>
                <p>
                  Offer customers assistance with any payment or service-related issues they encounter, including, but
                  not limited to,
                  setting a help center, hot line or an AI live chat connected to the technical support center.
                </p>
                <ul className="section-provide-keywords">
                  <li>Fuel</li>
                  <li>Parking</li>
                  <li>Food &
                    Beverage
                  </li>
                  <li>Curbside
                    Pickup
                  </li>
                  <li>Pre-order &
                    Reservation from Retails
                  </li>
                  <li>
                    Insurance
                  </li>
                </ul>
              </div>
              {/* provide item - 2 */}
              <div className="carousel-item section-provide-item" id="SectionProvide2">
                <h5>
                  Ad Platform Integration
                </h5>
                <p>
                  Provide a solution for OEM to seamlessly integrate ad platform into their in-vehicle infotainment
                  (IVI) systems,
                  ensuring that the ads are displayed properly and that the necessary data is captured with compliance
                  and security.
                </p>
                <h5>
                  Ad Content Management
                </h5>
                <p>
                  Offer tools and support for OEM to manage and curate the ad content that is displayed
                  in their IVI systems,
                  including the ability to customize ad targeting and personalization options based on user data and
                  preferences.
                </p>
                <h5>
                  Ad Revenue Management
                </h5>
                <p>
                  Provide OEM with a way to manage the revenue generated from ad placements in their IVI systems,
                  including
                  payment processing and reporting.
                </p>
                <h5>
                  Customer Service
                  and Support Solution
                </h5>
                <p>
                  Provide OEM with dedicated customer support to
                  help them integrate and manage ad platform
                  in their IVI systems.
                </p>
                <ul className="section-provide-keywords">
                  <li>Personalization
                    Ads
                  </li>
                  <li>Location-
                    based Ads
                  </li>
                  <li>Contextual
                    Ads
                  </li>
                  <li>Interactive
                    Ads – CPS
                  </li>
                  <li>
                    Sponsored
                    Content
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}