import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { useEffect } from "react";
import { service1, service2 } from "../assets/dummy/dummy";
import Footer from "../component/Footer";
import News from "../component/News";
import Section1 from "../component/Section1";
import Section3 from "../component/Section3";
import Section4 from "../component/Section4";
import { useNavigate } from "react-router-dom";

export default function Main(props: any) {
  const navigate = useNavigate();

  useEffect(() => {
    const element = document.getElementById(
      props.hash ?? window.location.hash.replace("#", "")
    );
    if (element) {
      element.scrollIntoView({
        behavior: "auto",
      });
    }

    // 지역 변경
    const defaultLocale = navigator?.language; // ko, ko-KR 등으로 전달됨
    const savedLocale = localStorage.getItem("locale");

    // 영문 홈페이지로 이동 (영문 홈페이지 직접 접속시는 체크하지 않음)
    if (defaultLocale && defaultLocale.indexOf('ko') !== 0) { // 기본 언어 감지 && 기본 언어가 한국어가 아닐 경우 체크
      if (
        !savedLocale || // 언어 변경한적 없거나
        (savedLocale.indexOf('ko') !== 0)  // 변경 언어가 한국어가 아닌 경우 (마지막에 Eng 눌러서 접속했을 경우, 해당 언어를 디폴트로 간주)
      ) {
        navigate('/en');
      }
    }

  }, []);

  return (
    <div id="home">
      {/* <div
        id="event_drone"
        style={{
          position: "fixed",
          width: "100%",
          zIndex: 999,
          padding: "0 10vw",
          display: "flex",
        }}
      >
        <img
          src={require("../assets/png/34524@2x.png")}
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.href =
              "https://m.hanacard.co.kr/MKCDCM1010M.web?CD_PD_SEQ=15618&utm_source=owin&utm_medium=affiliates&utm_campaign=202212_owin";
          }}
        />
      </div> */}
      <Section1 id="Section1" data={service1} type="carousel-fade">
        <div
          id="sns"
          style={{
            position: "absolute",
            right: "10vw",
            height: "100%",
            zIndex: 888,
            alignItems: "center",
          }}
        >
          <div>
            <img
              src={require("../assets/png/sns_youtube.png")}
              onClick={() => {
                window.location.href = "https://www.youtube.com/@owin_official";
              }}
            />
            <img
              src={require("../assets/png/sns_insta.png")}
              onClick={() => {
                window.location.href = "https://www.instagram.com/owin__official/";
              }}
            />
            <img
              src={require("../assets/png/sns_facebook.png")}
              onClick={() => {
                window.location.href = "https://www.facebook.com/owinauto";
              }}
            />
            <img
              src={require("../assets/png/sns_blog.png")}
              onClick={() => {
                window.location.href = "https://blog.naver.com/owin_carpay";
              }}
            />
          </div>
        </div>
      </Section1>

      <Section1 id="Section2" data={service2}/>

      {/* section2 start */}
      {/* <div className="main-section bg-light">
        <div
          className="position-absolute top-50 start-50 translate-middle"
          style={{ width: "100%" }}
        >
          <span className="OWiN-PASS fs-1">
            <strong>OWiN PASS</strong>
          </span>
          <div
            className="text-center fw-light section2-txt"
            style={{ marginTop: 30 }}
          >
            차 안에서 어떤 서비스를 자주 이용하시나요?
            <br />
            A.B.C형 세가지 중 나에게 맞는 서비스 구성을 픽!
            <br />월 9,900원에 이 모든 혜택을 누려보세요.
          </div>
          <div
            style={{ marginTop: 58, display: "flex", justifyContent: "center" }}
          >
            <Scroll className="bottom-0 end-0" />
          </div>
        </div>
      </div> */}
      {/* section2 end */}

      <Section3/>
      <Section4/>
      <News/>
      <Footer/>
    </div>
  );
}
