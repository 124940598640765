import { ReactComponent as OwinLogo } from "../../assets/svg/en/logo_owin.svg";
import { ReactComponent as HomeIcon } from "../../assets/svg/en/home_icon.svg";
import { ReactComponent as CarIcon } from "../../assets/svg/en/car_icon.svg";
import { ReactComponent as MailIcon } from "../../assets/svg/en/mail_icon.svg";
// @ts-ignore
import Scrollspy from 'react-scrollspy'
import React from "react";
import NavLanguage from "../NavLanguage";

export default function EnHeader() {
  return (
    <header>
      <nav className={`navbar navbar-expand fixed-top`} id="navbar">
        <div className="container-fluid" style={{ padding: 0 }}>
          <a className="navbar-brand" href="#home">
            {/*<OwinLogo/>*/}
            <img id="owin_logo" src={require("../../assets/png/owin_logo.png")} />
          </a>
          <Scrollspy className="navbar-nav ms-auto mb-md-0" items={['home', 'provide', 'contact']}
                     currentClassName="active"  offset={-88}>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#home"
                ref={React.createRef()}
              >
                <span>
                  Home
                <HomeIcon/>
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#provide"
                ref={React.createRef()}
              >
                 <span>
                  What We Provide
                <CarIcon/>
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#contact"
                ref={React.createRef()}
              >
                <span>
                  Contact Us
                <MailIcon/>
                </span>
              </a>
            </li>
          </Scrollspy>
          <NavLanguage language="en"/>
        </div>
      </nav>
    </header>
  )
}