import { Outlet } from "react-router-dom";
import EnHeader from "./EnHeader";
import "../../EnApp.scss";
export default function EnLayout() {
    return (
      <div id="en">
          <EnHeader />
          <Outlet />
      </div>
    )
}
