import axios from "axios";
import React, { useEffect } from "react";

export default function News() {
  const [data, setData] = React.useState<
    Array<{
      no: number;
      ds_title: string;
      ds_content: string;
      ds_popup_thumb: string;
      dt_reg: string;
    }>
  >();
  const [pageSize, setPageSize] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(0);
  const [clickData, setClickData] = React.useState<{
    no: number;
    ds_title: string;
    ds_content: string;
    ds_popup_thumb: string;
    dt_reg: string;
  }>();

  //뉴스 api 통신(get)
  const getData = async () => {
    try {
      const res = await axios.get(
        `https://owin-api.owinpay.com/v1/notice/gets?size=${pageSize}&offset=${currentPage}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      setData(res.data.rows);

      if (res.data.total_cnt % 10 == 0) {
        setTotalPage(res.data.total_cnt / 10);
      } else {
        setTotalPage(Number((res.data.total_cnt / 10).toFixed(0)));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  return (
    <div className="news">
      <div className="main-section" id="NEWS">
        <div>
          <p style={{ fontWeight: "bold", fontSize: "3.5vmax" }}>
            <strong>NEWS</strong>
          </p>
        </div>
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <div style={{ width: "100%" }}>
            <table className="table">
              <tbody>
                {data &&
                  data.map((item, idx) => (
                    <tr
                      key={idx}
                      onClick={() => setClickData(item)}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      style={{ cursor: "pointer" }}
                    >
                      <td className="td-title">
                        {item.ds_title.replace(/^(.{25})(.*)$/g, "$1...")}
                      </td>
                      <td className="td-date" style={{ textAlign: "right" }}>
                        {item.dt_reg.split(" ")[0]}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div
              className="paging"
              style={{ textAlign: "center", marginTop: "6vh" }}
            >
              <i
                className="bi bi-chevron-left"
                style={{ cursor: "pointer", padding: "15px" }}
                onClick={() => {
                  if (currentPage === 1) return;
                  setCurrentPage(currentPage - 1);
                }}
              ></i>
              <span style={{ fontWeight: "bold", margin: "0 10px" }}>
                {currentPage < 10 ? "0" + currentPage : currentPage}
              </span>
              <span>|</span>
              <span style={{ margin: "0 10px" }}>{totalPage}</span>
              <i
                className="bi bi-chevron-right"
                style={{ cursor: "pointer", padding: "15px" }}
                onClick={() => {
                  if (currentPage === totalPage) return;
                  setCurrentPage(currentPage + 1);
                }}
              ></i>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div
            className="modal-content"
            style={{ backgroundColor: "rgba(255,255,255,1)" }}
          >
            <div className="modal-header">
              <div>
                <p className="ds_title" style={{ fontWeight: "bold" }}>
                  {clickData?.ds_title.replace(/^(.{25})(.*)$/g, "$1...")}
                </p>
                <p style={{ fontSize: 16 }}>
                  {clickData?.dt_reg.split(" ")[0]}
                </p>
              </div>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {clickData?.ds_popup_thumb && (
                <img src={clickData.ds_popup_thumb} style={{ width: "100%" }} />
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    `
                  <style>
                    .news .modal-body img{ width:100%}
                  </style>
                  ${clickData?.ds_content}` ?? "",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
